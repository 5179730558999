import { IDeviceCheckinProcessConfiguration } from "./device-checkin-process-configuration.interface";

export class DeviceCheckinProcessConfiguration implements IDeviceCheckinProcessConfiguration {
  hasFacialRecognition?: boolean = undefined;
  hasPassportScanner?: boolean = undefined;
  hasReservation?: boolean = undefined;
  hasManualFlow?: boolean = undefined;
  hasGuestFlow?: boolean = undefined;
  hasTraineeFlow?: boolean = undefined;
  hasReprintBadgeFlow?: boolean = undefined;
  hasCovidDeclarationFlow?: boolean = undefined;
  hasDailyCheckinCheckoutFlow?: boolean = undefined;
  hasIHaveAQrCodeFlow?: boolean = undefined;
  hasCoronavirusScreen?:boolean = undefined;
  hasEmailMandatory?: boolean = undefined;
  hasPrintMandatory?: boolean = undefined;
  hasExtraInformationPage?: boolean = undefined;
  hasElectronicGovernmentId?: boolean = undefined;
  hasSurvey?:boolean = undefined;
  hasSkipIdentifyDocumentType?: boolean = undefined;
  
  constructor(data?: IDeviceCheckinProcessConfiguration) {
    if(data) {
      Object.assign(this, data);
    }
  }
}